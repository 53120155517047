/*****************************************************************************
 * UmanIT - Menu display product on hover on submenu items
 *****************************************************************************/
const items = Array.from(document.querySelectorAll("[data-trigger^='submenuitem-']"));

if(breakpoints.isDesktop()) {
  items.forEach(item => {
    item.addEventListener("mouseover", displayProduct);
    const parentLi = item.closest(".sub-menu__item");
    parentLi.addEventListener("mouseleave", hideProduct);
  });
}

/**
 * Fait apparaître le produit mis en avant au survol de l'item de sous-menu
 *
 */
function displayProduct() {
  const submenuItem = this.dataset.trigger;
  const product = document.querySelector(`[data-target='${submenuItem}']`);
  if(product) {
    product.classList.add("active");
  }
}

/**
 * Fait disparaître le produit mis en avant lorsque le curseur qui l'item de sous-menu
 *
 */
function hideProduct() {
  const product = this.querySelector(".submenu-container__cross-product");
  if(product) {
    product.classList.remove("active");
  }
}
